<template>
  <div>
    <v-col lg="10">
      <v-row class="py-3" align="center"
        ><v-col class="pa-0 px-2 d-flex" cols="6">
          <p class="mr-3 my-auto d-inline">Bestellanzahl p.a.</p>
          <v-text-field
            min="0"
            dense
            type="number"
            v-model.number="inputData.yearlyOrderCount"
            @change="calculate()"
            suffix="Stk"
            hide-details
            class="d-inline"
          ></v-text-field></v-col
        ><v-col class="pa-0 px-2 d-flex" cols="5">
          <p class="mr-3 my-auto d-inline">Vollkosten</p>
          <v-text-field
            min="0"
            dense
            type="number"
            suffix="€/Std"
            hide-details
            class="d-inline"
            v-model.number="inputData.globalProcessCost"
            @change="overwriteProcessCosts()"
          ></v-text-field></v-col></v-row
    ></v-col>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-center"></th>
            <th colspan="3" class="text-center">IST-Kosten</th>
            <th colspan="2" class="text-center">Optimiert durch el/ka/sy®</th>
          </tr>
          <tr>
            <th class="text-center">Prozessschritt</th>
            <th class="text-center">Vollkosten (€/Std)</th>
            <th class="text-center">Zeitaufwand (Min)</th>
            <th class="text-center">Gesamtkosten</th>
            <th class="text-center table-divider">⌀ Einsparung</th>
            <th class="text-center">Ihre Einsparung</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(processStep, index) in inputData.processSteps" :key="processStep.name">
            <td>
              <p class="ma-0" style="text-align: left">
                <v-icon data-html2canvas-ignore class="mr-2"> {{ processStep.icon }} </v-icon>{{ processStep.name }}
              </p>
            </td>
            <td>
              <v-text-field
                class="table-textfield"
                v-bind:class="{ 'textfield-no-underline': index == inputData.processSteps.length - 1 }"
                min="0"
                v-model.number="inputData.processSteps[index].costPerHour"
                height="25px"
                type="number"
                dense
                hide-details
                @change="calculate()"
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                class="table-textfield"
                v-bind:class="{ 'textfield-no-underline': index == inputData.processSteps.length - 1 }"
                v-model.number="inputData.processSteps[index].timeExpense"
                min="0"
                value="0"
                height="25px"
                type="number"
                dense
                hide-details
                @change="calculate()"
              ></v-text-field>
            </td>
            <td>
              {{ displayNumber(processStep.overallProcessCost, 2, "€") }}
            </td>
            <td class="table-divider">{{ displayNumber(processStep.averageSavings, 0, "%") }}</td>
            <td>{{ displayNumber(processStep.actualSavings, 2, "€") }}</td>
          </tr>
          <tr>
            <td class="text-left simple-table-result-line">Gesamtkosten pro Prozess</td>
            <td class="simple-table-result-line"></td>
            <td class="simple-table-result-line"></td>
            <td class="simple-table-result-line">{{ displayNumber(resultData.overallCost, 2, "€") }}</td>
            <td class="simple-table-result-line table-divider"></td>
            <td class="simple-table-result-line"></td>
          </tr>
          <tr>
            <td class="text-left">jährliche Gesamtkosten</td>
            <td></td>
            <td></td>
            <td>{{ displayNumber(resultData.yearlyOverallCost, 2, "€") }}</td>
            <td class="table-divider"><h3></h3></td>
            <td></td>
          </tr>
          <tr>
            <td class="text-left">Gesamteinsparungen pro Prozess</td>
            <td></td>
            <td></td>
            <td></td>
            <td class="table-divider"><h3></h3></td>
            <td>
              <h3>{{ displayNumber(resultData.overallSavings, 2, "€") }}</h3>
            </td>
          </tr>
          <tr>
            <td class="text-left">jährliche Gesamteinsparungen</td>
            <td></td>
            <td></td>
            <td></td>
            <td class="table-divider"><h3></h3></td>
            <td>
              <h3>{{ displayNumber(resultData.yearlyOverallSavings, 2, "€") }}</h3>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import ProcessStep from "../../processStep.js";
import utilsMixin from "../../util.js";

export default {
  mixins: [utilsMixin],
  data() {
    return {
      inputData: {
        globalProcessCost: 0,
        yearlyOrderCount: 3098,
        processSteps: [
          new ProcessStep("Bedarfsentstehung", "mdi-clipboard-alert", 65, 7, 90),
          new ProcessStep("Bedarfsidentifikation", "mdi-clipboard-search", 65, 3, 90),
          new ProcessStep("Bedarfsanforderung ausfüllen", "mdi-clipboard-edit", 65, 4, 100),
          new ProcessStep("Bedarfsanforderung genehmigen", "mdi-clipboard-arrow-up", 65, 3, 100),
          new ProcessStep("Bedarfsanforderung weiterleiten", "mdi-clipboard-play", 65, 2, 100),
          new ProcessStep("Bestelldaten prüfen", "mdi-clipboard-text-search", 65, 5, 100),
          new ProcessStep("Lieferantensuche", "mdi-truck-check", 65, 3, 0),
          new ProcessStep("Preisvergleiche", "mdi-currency-eur", 65, 3, 0),
          new ProcessStep("Bestellung anlegen", "mdi-clipboard-edit", 65, 6, 100),
          new ProcessStep("Bestellung versenden", "mdi-clipboard-text-play", 65, 2, 100),
          new ProcessStep("Ware entgegennehmen und prüfen", "mdi-archive-search", 65, 10, 30),
          new ProcessStep("Wareneingang verbuchen", "mdi-archive-check", 65, 4, 90),
          new ProcessStep("Ware verteilen und einlagern", "mdi-archive-arrow-up", 65, 10, 20),
          new ProcessStep("Rechnungsprüfung", "mdi-receipt", 65, 10, 75),
          new ProcessStep("Rechnung verbuchen", "mdi-receipt", 65, 3, 90),
          new ProcessStep("Zahlungsanweisung", "mdi-credit-card-marker", 65, 3, 0),
          new ProcessStep("Reklamationsbearbeitung", "mdi-cash-refund", 65, 3, 0),
        ],
      },
      resultData: {
        yearlyOverallCost: 0,
        yearlyOverallSavings: 0,
        yearlySavingsPotential: 0,
        overallCost: 0,
        overallSavings: 0,
        overallTimeExpense: 0,
        overallCostPerHour: 0,
      },
    };
  },
  methods: {
    calculate() {
      let overallTimeExpense = 0;
      let overallCostPerHour = 0;
      let overallCost = 0;
      let overallSavings = 0;

      for (var index = 0; index < this.inputData.processSteps.length; index++) {
        let processStep = this.inputData.processSteps[index];
        processStep.calc();

        overallTimeExpense += processStep.timeExpense;
        overallCostPerHour += processStep.costPerHour;
        overallCost += processStep.overallProcessCost;
        overallSavings += processStep.actualSavings;
      }

      this.resultData.overallCost = overallCost;
      this.resultData.overallCostPerHour = overallCostPerHour;
      this.resultData.overallTimeExpense = overallTimeExpense;
      this.resultData.overallSavings = overallSavings;

      this.resultData.yearlyOverallCost = overallCost * this.inputData.yearlyOrderCount;
      this.resultData.yearlyOverallSavings = overallSavings * this.inputData.yearlyOrderCount;

      if (
        !isNaN(this.resultData.yearlyOverallCost) &&
        this.resultData.yearlyOverallCost > 0 &&
        !isNaN(this.resultData.yearlyOverallSavings) &&
        this.resultData.yearlyOverallSavings > 0
      ) {
        let delta = this.resultData.yearlyOverallCost - this.resultData.yearlyOverallSavings;
        let ratio = 1 - delta / this.resultData.yearlyOverallCost;
        this.resultData.yearlySavingsPotential = ratio * 100;
      }
      this.emitCalculatedEvent();
    },
    overwriteProcessCosts() {
      for (var index = 0; index < this.inputData.processSteps.length; index++) {
        let processStep = this.inputData.processSteps[index];
        processStep.costPerHour = this.inputData.globalProcessCost;
      }
      this.calculate();
    },
    emitCalculatedEvent() {
      this.$emit("calculated-event", this.resultData);
    },
  },
  mounted() {
    this.calculate();
  },
};
</script>

<style scoped>
.textfield-no-underline >>> .v-input__slot::before {
  border-style: none !important;
}
td {
  text-align: right;
}

.top-table-textfield {
  width: 120px;
}

.table-textfield {
  width: 90px;
}

.simple-table-result-line {
  border-top: 2px solid #7d024f;
}

.table-divider {
  border-left: 2px solid #7d024f;
}
</style>
