<template>
  <div>
    <v-row class="mt-15"
      ><v-col>
        <SavingsChart
          :costsAfterSavings="resultData.yearlyOverallCost - resultData.yearlyOverallSavings"
          :costReduction="resultData.yearlyOverallSavings"
        ></SavingsChart>
        <div class="mt-7">
          <h3 class="text-center pb-1">Ihr Einsparpotenzial:</h3>
          <CountingDisplay :val="resultData.yearlySavingsPotential" unit="%"></CountingDisplay>
        </div> </v-col
    ></v-row>
    <v-row class="mt-7"
      ><v-col class="text-center">
        <p class="px-6">
          Mit unseren schlanken Beschaffungslösungen können Sie eine Einsparung von bis zu
          <span class="font-weight-bold">{{ displayNumber(resultData.yearlyOverallSavings, 0, "€") }}</span> jährlich erreichen. Dies
          entspricht einer <span class="font-weight-bold">{{ displayNumber(resultData.yearlySavingsPotential, 0, "%") }}</span
          >-tigen Kostenreduktion.
        </p>
        <p class="px-6">
          Gerne prüfen wir in einem persönlichen Gespräch gemeinsam Ihre Optimierungspotentiale. Buchen Sie einfach ein unverbindliches
          Beratungsgespräch mit unseren Experten.
        </p>
        <div class="d-flex justify-center">
          <v-btn
            target="_blank"
            href="https://www.ps-cooperation.de/demo-vereinbaren/"
            class="white--text rounded-0 mt-2 mr-2"
            color="primary"
            >Beratung buchen</v-btn
          >
          <DownloadDlg @download-button-pressed-event="emitDownloadButtonPressedEvent()"></DownloadDlg>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CountingDisplay from "./CountingDisplay.vue";
import SavingsChart from "./SavingsChart.vue";
import utilsMixin from "../../util.js";
import DownloadDlg from "./DownloadDlg.vue";

export default {
  components: { CountingDisplay, SavingsChart, DownloadDlg },
  mixins: [utilsMixin],
  props: ["resultData"],
  methods: {
    emitDownloadButtonPressedEvent() {
      console.log(this.resultData);
      this.$emit("download-button-pressed-event");
    },
  },
};
</script>

<style></style>
