<template>
  <h2 class="text-center">{{ displayNumber(costsCounter, 0, unit) }}</h2>
</template>

<script>
import utilMixin from "../../util.js";

export default {
  props: ["val", "unit"],
  mixins: [utilMixin],
  data() {
    return {
      costsCounter: 0,
      testVal: 0,
    };
  },
  mounted() {
    this.countCost();
  },
  methods: {
    countCost() {
      this.costsCounter = 0;
      const countDownTimeMilliSec = 3000;
      const decPerTic = (this.val / countDownTimeMilliSec) * 15;

      setInterval(() => {
        if (this.costsCounter < this.val) {
          if (this.costsCounter + decPerTic > this.val) {
            this.costsCounter = this.costsCounter + 1;
          } else {
            this.costsCounter = this.costsCounter + decPerTic;
          }
        } else {
          return null;
        }
      }, 1);
      this.testVal = this.costsCounter;
    },
  },
  watch: {
    val: function () {
      this.countCost();
    },
  },
};
</script>

<style></style>
